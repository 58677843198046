import { gql } from "@apollo/client";

const FRAGMENT_USER_FIELDS = gql`
  fragment UserFields on User {
    id
    name
    email
    password
    googleId
    role
    companyIds
  }
`;

export const FRAGMENT_ADDR_FIELDS = gql`
  fragment AddrFields on Addr {
    roadAddr
    plainKor
    addrEng
    plainEng
    street
    detail
    legalDong
    building
    zipCode
    jibun
    adminDong
    li
    pnu
    date
    change
    jurisdiction
  }
`;

const FRAGMENT_SHAREINFO_FIELDS = gql`
  fragment ShareInfoFields on ShareInfo {
    name
    nameSynonim
    issuePrice
    amount
    bank
    invested
    howto
    detail
    shareDetail
  }
`;

const FRAGMENT_SHARE_FIELDS = gql`
  fragment ShareFields on Share {
    name
    amount
  }
`;

const FRAGMENT_BUSINESS_FIELDS = gql`
  fragment BusinessFields on Business {
    changeOptions
    oldOne
    newOne
  }
`;

const FRAGMENT_REP_FIELDS = gql`
  fragment RepFields on Rep {
    foreigner
    positionName
    nameKor
    nameEng
    SSN
    nationality
    birthday

    addr {
      ...AddrFields
    }

    repOfRepForeigner
    repOfRepPositionName
    repOfRepNameKor
    repOfRepNameEng
    repOfRepSSN
    repOfRepNationality
    repOfRepBirthday

    repOfRepAddr {
      ...AddrFields
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
`;

export const FRAGMENT_LEGALENTITY_FIELDS = gql`
  fragment LegalEntityFields on LegalEntity {
    legalEntityType
    foreigner
    nameKor
    nameEng
    SSN
    nationality
    birthday

    addr {
      ...AddrFields
    }

    chair
    repAuthority
    positionName
    start
    end
    status

    promotor
    inspector
    farmer
    email
    tel

    attendBoD
    attendSH
    agree
    notary

    share {
      ...ShareFields
    }
    issuePrice
    bank
    invested
    offset

    bondName
    bond
    bondDate
    offsetAmount

    rep {
      ...RepFields
    }
    operator {
      ...RepFields
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
  ${FRAGMENT_SHARE_FIELDS}
  ${FRAGMENT_REP_FIELDS}
`;

const FRAGMENT_MEETUP_FIELDS = gql`
  fragment MeetUpFields on MeetUp {
    name
    regular
    writtenResolution
    date
    start
    end
    place
  }
`;

const FRAGMENT_TREASURY_STOCK_ADD_ON_FIELDS = gql`
  fragment TreasuryStockAddOnFields on TreasuryStockAddOn {
    externalAudit
    changeArticle
    duration
    price
    goal
    share {
      ...ShareFields
    }
  }
  ${FRAGMENT_SHARE_FIELDS}
`;

const FRAGMENT_CB_ADD_ON_FIELDS = gql`
  fragment CBAddOnFields on CBAddOn {
    name
    total
    parValue
    interest
    invested
    maturity
    convertTo
    period
    repay
    convertCondition
  }
`;

const FRAGMENT_RESERVE_ADD_ON_FIELDS = gql`
  fragment ReserveAddOnFields on ReserveAddOn {
    desc
    rate
    allocation
  }
`;

const FRAGMENT_STOCKOPTIONLIST_FIELDS = gql`
  fragment StockOptionListFields on StockOptionList {
    name
    position
    share
    price
  }
`;

const FRAGMENT_BRANCH_FIELDS = gql`
  fragment BranchFields on Branch {
    name
    addr {
      ...AddrFields
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
`;

const FRAGMENT_ARTICLE_FIELDS = gql`
  fragment ArticleFields on Article {
    num
    sub
    changeOptions
    reason
    moreInfo
    oldContent
    newContent
  }
`;

const FRAGMENT_LOCALTAXNFEE_FIELDS = gql`
  fragment LocalTaxNFeeFields on LocalTaxNFee {
    title
    taxNo
    taxType
    regTax
    regFee
    payArea {
      ...AddrFields
    }
    seoulMetro
    sandan
    software
    moveToCountry
    after5years
    SPC
    startup
    agriCompany
    socialVenture
    analysis
  }
`;

const FRAGMENT_OTHEREXPENSE_FIELDS = gql`
  fragment OtherExpenseFields on OtherExpense {
    key
    value
  }
`;

const FRAGMENT_ADDITIONALDECISION_FIELDS = gql`
  fragment AdditionalDecisionFields on AdditionalDecision {
    decisionType
    title
    appendixTitle
    consent
    appendixContent
  }
`;

const FRAGMENT_APPROVAL_FIELDS = gql`
  fragment ApprovalFields on Approval {
    consent
    appendixTitle
    appendixContent
  }
`;

export const FRAGMENT_REGDOC_FIELDS = gql`
  fragment RegDocFields on RegDoc {
    companyType
    nameKor
    regType
    eReg
    newBoD
    newRepTitle

    changeOfficers
    changeOfficerPay
    changeBusiness
    changeAddr
    changeName
    changeNotice

    changeCapital
    changeAuthorizedShares
    changeParValue
    treasuryStock
    changeConvertibleBond
    changeToLLC

    changeStockOption
    giveStockOption
    changeBranch
    changeClearing
    changeAllArticle
    notaryArticle

    investorsMeet {
      ...MeetUpFields
    }
    investorsMeet2 {
      ...MeetUpFields
    }
    officersMeet {
      ...MeetUpFields
    }
    officersMeet2 {
      ...MeetUpFields
    }

    officers {
      ...LegalEntityFields
    }
    investors {
      ...LegalEntityFields
    }

    officersNew {
      ...LegalEntityFields
    }
    directorPay
    directorBonus
    auditorPay
    auditorBonus
    businessNew {
      ...BusinessFields
    }
    addrNew {
      ...AddrFields
    }
    nameKorNew
    nameEngNew
    noticeMediaNew

    showSubscribers
    showBank
    issuedSharesNew {
      ...ShareInfoFields
    }
    capitalNew
    investorsNew {
      ...LegalEntityFields
    }
    authorizedSharesNew
    parValueNew
    treasuryStockAddOn {
      ...TreasuryStockAddOnFields
    }
    CBAddOn {
      ...CBAddOnFields
    }
    reserveAddOn {
      ...ReserveAddOnFields
    }
    stockOptionPeriod
    stockOptionLimit
    stockOptionPrice
    howtoGiveStockOption
    stockOptionCondition
    stockOptionList {
      ...StockOptionListFields
    }
    branchesNew {
      ...BranchFields
    }

    articles {
      ...ArticleFields
    }

    additionalDecisions {
      ...AdditionalDecisionFields
    }
    approvals {
      ...ApprovalFields
    }

    serviceFee
    localTaxNFee {
      ...LocalTaxNFeeFields
    }
    otherExpense {
      ...OtherExpenseFields
    }

    memo
    companyId
  }
  ${FRAGMENT_MEETUP_FIELDS}
  ${FRAGMENT_ADDR_FIELDS}
  ${FRAGMENT_SHAREINFO_FIELDS}
  ${FRAGMENT_BUSINESS_FIELDS}
  ${FRAGMENT_LEGALENTITY_FIELDS}
  ${FRAGMENT_TREASURY_STOCK_ADD_ON_FIELDS}
  ${FRAGMENT_CB_ADD_ON_FIELDS}
  ${FRAGMENT_RESERVE_ADD_ON_FIELDS}
  ${FRAGMENT_STOCKOPTIONLIST_FIELDS}
  ${FRAGMENT_BRANCH_FIELDS}
  ${FRAGMENT_ARTICLE_FIELDS}
  ${FRAGMENT_LOCALTAXNFEE_FIELDS}
  ${FRAGMENT_OTHEREXPENSE_FIELDS}
  ${FRAGMENT_APPROVAL_FIELDS}
  ${FRAGMENT_ADDITIONALDECISION_FIELDS}
`;

export const FRAGMENT_COMPANY_FIELDS = gql`
  fragment CompanyFields on Company {
    companyType
    socialVenture
    agriCompany
    oldRepTitle

    nameKor
    nameEng
    email
    tel
    webNotice
    noticeMedia

    addr {
      ...AddrFields
    }

    parValue
    issuedShares {
      ...ShareInfoFields
    }
    capital
    authorizedShares

    mailAddr {
      ...AddrFields
    }

    regNumShort
    regNumLong
    businessNo
    founded

    business
    officers {
      ...LegalEntityFields
    }
    investors {
      ...LegalEntityFields
    }
    branches {
      ...BranchFields
    }
    socialPurpose
    memo

    regDocIds
    userIds
    author
  }
  ${FRAGMENT_ADDR_FIELDS}
  ${FRAGMENT_SHAREINFO_FIELDS}
  ${FRAGMENT_LEGALENTITY_FIELDS}
  ${FRAGMENT_BRANCH_FIELDS}
`;

export const FRAGMENT_KEY_VALUE_FIELDS = gql`
  fragment KeyValueFields on KeyValue {
    key
    value
  }
`;

export const FRAGMENT_CATEGORY_KEY_VALUE_FIELDS = gql`
  fragment CategoryKeyValueFields on CategoryKeyValue {
    category
    keyValue {
      ...KeyValueFields
    }
  }
  ${FRAGMENT_KEY_VALUE_FIELDS}
`;

export const ME = gql`
  query ME {
    me {
      name
      email
      googleId
    }
  }
`;

export const COMPANY = gql`
  query COMPANY($id: ID!) {
    company(id: $id) {
      ...CompanyFields
      created
      modified
    }
  }
  ${FRAGMENT_COMPANY_FIELDS}
`;

export const ALL_COMPANIES = gql`
  query ALL_COMPANIES(
    $first: Int
    $start: Int
    $sort: SortDirection
    $sortBy: SortableField
  ) {
    allCompanies(first: $first, start: $start, sort: $sort, sortBy: $sortBy) {
      id
      nameKor
      email
      addr {
        ...AddrFields
      }
      tel
      created
      modified
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
`;

export const CHECK_TERM = gql`
  query CHECK_TERM(
    $first: Int
    $start: Int
    $sort: SortDirection
    $sortBy: SortableField
  ) {
    checkTerm(first: $first, start: $start, sort: $sort, sortBy: $sortBy) {
      id
      companyType
      nameKor
      officers {
        id
        nameKor
        positionName
        start
        end
        email
      }
      addr {
        ...AddrFields
      }
      founded
      tel
      email
      created
      modified
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
`;

export const ALL_TERM = gql`
  query ALL_TERM(
    $first: Int
    $start: Int
    $sort: SortDirection
    $sortBy: SortableField
  ) {
    allTerm(first: $first, start: $start, sort: $sort, sortBy: $sortBy) {
      id
      companyType
      nameKor
      officers {
        id
        nameKor
        positionName
        start
        end
        email
      }
      addr {
        ...AddrFields
      }
      founded
      tel
      email
      created
      modified
    }
  }
  ${FRAGMENT_ADDR_FIELDS}
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CompanyInput!) {
    createCompany(input: $input)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: ID!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input)
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id)
  }
`;

export const ADD_INVESTORS = gql`
  mutation addInvestors($id: ID!, $input: [LegalEntityInput!]!) {
    addInvestors(id: $id, input: $input)
  }
`;

export const REGDOC = gql`
  query REGDOC($id: ID!) {
    regDoc(id: $id) {
      ...RegDocFields
      author
      latestFolder
      created
      modified
    }
  }
  ${FRAGMENT_REGDOC_FIELDS}
`;

export const REGDOCS = gql`
  query REGDOCS(
    $first: Int
    $start: Int
    $sort: SortDirection
    $sortBy: SortableField
    $companyId: ID
  ) {
    regDocs(
      first: $first
      start: $start
      sort: $sort
      sortBy: $sortBy
      companyId: $companyId
    ) {
      id
      nameKor
      regType

      changeBusiness
      changeAddr
      changeOfficers
      changeCapital
      changeAuthorizedShares
      changeStockOption
      giveStockOption
      changeBranch
      changeName
      changeOfficerPay
      changeNotice
      changeParValue
      changeConvertibleBond
      treasuryStock
      changeClearing
      changeToLLC

      companyId
      created
      modified
    }
  }
`;

export const CREATE_REGDOC = gql`
  mutation createRegDoc($input: RegDocInput!) {
    createRegDoc(input: $input)
  }
`;

export const UPDATE_REGDOC = gql`
  mutation updateRegDoc($id: ID!, $input: RegDocInput!) {
    updateRegDoc(id: $id, input: $input)
  }
`;

export const DELETE_REGDOC = gql`
  mutation deleteRegDoc($id: ID!) {
    deleteRegDoc(id: $id)
  }
`;

export const WRITE_DOCS = gql`
  mutation writeDocs($id: ID!) {
    writeDocs(id: $id)
  }
`;

export const CATEGORY = gql`
  query CATEGORY($id: ID!) {
    categoryKeyValue(id: $id) {
      ...CategoryKeyValueFields
      created
      modified
    }
  }
  ${FRAGMENT_CATEGORY_KEY_VALUE_FIELDS}
`;

export const ALL_CATEGORIES = gql`
  query ALL_CATEGORIES(
    $first: Int
    $start: Int
    $sort: SortDirection
    $sortBy: SortableField
    $category: String
  ) {
    allCategoryKeyValues(
      first: $first
      start: $start
      sort: $sort
      sortBy: $sortBy
      category: $category
    ) {
      id
      ...CategoryKeyValueFields
      created
      modified
    }
  }
  ${FRAGMENT_CATEGORY_KEY_VALUE_FIELDS}
`;

export const CREATE_CATEGORY_KEY_VALUE = gql`
  mutation createCategoryKeyValue($input: CategoryKeyValueInput!) {
    createCategoryKeyValue(input: $input)
  }
`;

export const UPDATE_CATEGORY_KEY_VALUE = gql`
  mutation updateCategoryKeyValue($id: ID!, $input: CategoryKeyValueInput!) {
    updateCategoryKeyValue(id: $id, input: $input)
  }
`;

export const TAX_DATA = gql`
  mutation taxData($input: AddrInput!) {
    taxData(input: $input) {
      seoulMetro
      sandan
      analysis
    }
  }
`;

export const DUPLICATE = gql`
  mutation duplicate($companyName: String!, $jurisdiction: String) {
    duplicate(companyName: $companyName, jurisdiction: $jurisdiction) {
      noDuplicate
      analysis
    }
  }
`;
