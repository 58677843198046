const keys = {
  CompanyType: {
    CORP: 100,
    LTD: 200,
    LLC: 300
  },
  OfficerTitle: {
    CORP: {
      REP: 100,
      DIRECTOR: 200,
      COREP: 300,
      REPCEO: 400,
      CEO: 500,
      COCEO: 600,
      AUDITOR: 700,
      AUDIT_COMMITTEE: 800
    },
    LTD: {
      REP: 2100,
      DIRECTOR: 2200,
      COREP: 2300,
      AUDITOR: 2400
    },
    LLC: {
      REPCEO: 4100,
      CEO: 4200,
      COCEO: 4300
    }
  },
  RegType: {
    NEW: 100,
    CHANGE: 200
  },
  ChangeCapital: {
    INC_3RD: 100,
    INC_SHAREHOLDERS: 200,
    FREEINC: 300,
    RESERVE: 400,
    STOCKOPTION: 500,
    DEC: 600
  },
  ChangeStockOption: {
    ADD: 100,
    MODIFY: 200,
    ADD_VENTURE: 300,
    MODIFY_VENTURE: 400,
    ADD_LISTED: 500,
    MODIFY_LISTED: 600
  },
  ChangeOfficerPay: {
    RESOLUTION: 100,
    ADD_RULES: 200,
    MODIFY_RULES: 300
  },
  ChangeNotice: {
    HOMEPAGE: 100,
    NEWSPAPER: 200
  },
  TreasuryStock: {
    ACQ: 100,
    BURN: 200
  },
  ChangeOptions: {
    ADD: 100,
    DELETE: 200,
    MODIFY: 300
  },
  AddrChange: {
    NEW: 100,
    DELETE: 200,
    MODIFY: 300,
    MOVE: 400,
    ROAD: 500
  },
  UserRole: {
    BEFORE: 100,
    MEMBER: 200,
    ADVANCED_MEMBER: 300,
    MANAGER: 400,
    ADMIN: 500
  },
  PositionStatus: {
    APPOINTED: 100,
    REAPPOINTED: 200,
    RESIGNED: 300,
    RETIRED: 400,
    DISMISSED: 500,
    MODIFIED: 600,
    DELETED: 700,
    ADDRESS_MODIFIED: 800,
    ADDRESS_ADDED: 900,
    ABOLITION: 1000
  },
  LegalEntityType: {
    PERSON: 100,
    LEGALPERSON: 200,
    FUND: 300,
    TRUST: 400
  },
  RegTax: {
    MAKE: 112500,
    MAKERATE: 0.004,
    HEAVY: 3,
    COMMON: 40200
  },
  EduTaxRate: 0.2,
  RegFee: {
    MAKE_EREG: 20000,
    MAKE_EFORM: 25000,
    COMMON_EREG: 2000,
    COMMON_EFORM: 4000
  },
  NONE: 0,
  blankSpace: "          ",
  irosName: "http://www.iros.go.kr/ifrontservlet?cmd=INSEWelcomeNseFrmC"
};

export default keys;
