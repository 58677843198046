import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { defaults, resolvers } from "./LocalState";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const cache = new InMemoryCache({
  //addTypename: false,
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `${accessToken}` : ""
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default new ApolloClient({
  link: authLink.concat(httpLink, errorLink),
  cache,
  clientState: {
    defaults,
    resolvers
  }
});
