import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import "src/assets/css/prism.css";
import "src/assets/css/custom.css";
import "src/mixins/chartjs";
import "src/mixins/prismjs";
import "src/mock";
import { enableES5 } from "immer";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "src/serviceWorker";
import { SettingsProvider } from "src/context/SettingsContext";
import { configureStore } from "src/store";
import { ApolloProvider } from "@apollo/client";
import Client from "src/Apollo/Client";
import App from "src/App";

enableES5();

const store = configureStore();

ReactDOM.render(
  <ApolloProvider client={Client}>
    <Provider store={store}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
