/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/Category";
import {
  Briefcase as BriefcaseIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Grid as GridIcon
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { useQuery } from "@apollo/client";
import { ME } from "src/Apollo/Queries";

const navConfig = [
  {
    subheader: "법인",
    href: "/app/company",
    items: [
      {
        title: "법인목록",
        href: "/app/company/list",
        icon: GridIcon
      },
      {
        title: "임기만료",
        href: "/app/company/term",
        icon: AlertCircleIcon
      },
      {
        title: "전체임기",
        href: "/app/company/allterm",
        icon: UsersIcon
      }
    ]
  },
  {
    subheader: "키값",
    href: "/app/keyvalue",
    items: [
      {
        title: "키값목록",
        href: "/app/keyvalue/list",
        icon: CategoryIcon
      }
    ]
  },
  /*
  
  {
    subheader: "Auth",
    items: [
      {
        title: "Login",
        href: "/login-unprotected",
        icon: LockIcon
      },
      {
        title: "Register",
        href: "/register-unprotected",
        icon: UserPlusIcon
      },
      {
        title: "Login: Guest Protected",
        href: "/login",
        icon: ShieldIcon
      },
      {
        title: "Register: Guest Protected",
        href: "/register",
        icon: ShieldIcon
      }
    ]
  },
  */
  {
    subheader: "관리",
    href: "/app/pages",
    items: [
      {
        title: "계정",
        href: "/app/account",
        icon: UserIcon
      }
      /*
      {
        title: "Error",
        href: "/404",
        icon: AlertCircleIcon
      },
     */
    ]
  }
  /*
  {
    subheader: "Extra",
    items: [
      {
        title: "Charts",
        href: "/app/extra/charts",
        icon: BarChartIcon,
        items: [
          {
            title: "Apex Charts",
            href: "/app/extra/charts/apex"
          }
        ]
      },
      {
        title: "Forms",
        href: "/app/extra/forms",
        icon: EditIcon,
        items: [
          {
            title: "Formik",
            href: "/app/extra/forms/formik"
          },
          {
            title: "Redux Forms",
            href: "/app/extra/forms/redux"
          }
        ]
      },
      {
        title: "Editors",
        href: "/app/extra/editors",
        icon: LayoutIcon,
        items: [
          {
            title: "DraftJS Editor",
            href: "/app/extra/editors/draft-js"
          },
          {
            title: "Quill Editor",
            href: "/app/extra/editors/quill"
          }
        ]
      }
    ]
  },
  {
    subheader: "Reports",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/app/reports/dashboard"
      },
      {
        title: "Dashboard Alternative",
        icon: BarChartIcon,
        href: "/app/reports/dashboard-alternative"
      }
    ]
  }
  */
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  /*
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  */
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const { loading, error, data } = useQuery(ME);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {data.me.name}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Hidden>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
