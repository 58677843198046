import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

function AuthGuard({ children }) {
  const accessToken = localStorage.getItem("accessToken");
  // console.log("AuthGuard accessToken", accessToken);
  if (accessToken !== null && accessToken !== "" && accessToken !== undefined) {
    return children;
  }

  return <Redirect to="/login" />;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
