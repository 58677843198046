import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import keys from "src/config/keys";

function GoogleAuthGuard({ children }) {
  const history = useHistory();
  try {
    useEffect(() => {
      fetch(process.env.REACT_APP_BACKEND_URL +  "/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true
        }
      })
        .then(response => {
          // console.log("GoogleAuthGuard then response", response);
          if (response.status === 200) return response.json();
          throw new Error("failed to authenticate user");
        })
        .then(responseJson => {
          // console.log("responseJson: ", responseJson);
          // console.log("responseJson.accessToken: ", responseJson.accessToken);
          if (
            responseJson.accessToken !== null &&
            responseJson.accessToken !== "" &&
            responseJson.accessToken !== undefined
          ) {
            if (responseJson.user.role >= keys.UserRole.MANAGER) {
              localStorage.setItem("accessToken", responseJson.accessToken);
              history.push("/app");

              console.log("login success!");
              return children;
            } else {
              return <Redirect to="/login" />;
            }
          } else {
            throw Error();
          }
        })
        .catch(error => {
          console.log("loginWithGoogle err ", error);
        });
    }, []);

    return children;
  } catch (error) {
    console.log("GoogleAuthGuard error: ", error);
    return <Redirect to="/login" />;
  }
}

GoogleAuthGuard.propTypes = {
  children: PropTypes.any
};

export default GoogleAuthGuard;
