/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import PropTypes from "prop-types";
import AuthGuard from "src/components/AuthGuard";
import GoogleAuthGuard from "src/components/GoogleAuthGuard";

const mainRoutes = [
  {
    exact: true,
    path: "/app",
    component: () => <Redirect to="/app/company/list" />
  },
  {
    exact: true,
    path: "/app/company",
    component: () => <Redirect to="/app/company/list" />
  },
  {
    exact: true,
    path: "/app/company/list",
    component: lazy(() => import("src/views/company/CompanyListView"))
  },
  {
    exact: true,
    path: "/app/company/term",
    component: lazy(() => import("src/views/company/CompanyTermView"))
  },
  {
    exact: true,
    path: "/app/company/allterm",
    component: lazy(() => import("src/views/company/CompanyAllTermView"))
  },
  {
    exact: true,
    path: "/app/company/create",
    component: lazy(() => import("src/views/company/CompanyUpsertView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId",
    component: lazy(() => import("src/views/company/CompanyDetailsView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId/edit",
    component: lazy(() => import("src/views/company/CompanyUpsertView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId/addregdoc",
    component: lazy(() => import("src/views/company/CompanyDetailsView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId/listregdoc",
    component: lazy(() => import("src/views/company/CompanyDetailsView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId/viewregdoc/:regDocId",
    component: lazy(() => import("src/views/company/CompanyDetailsView"))
  },
  {
    exact: true,
    path: "/app/company/:companyId/editregdoc/:regDocId",
    component: lazy(() => import("src/views/company/CompanyDetailsView"))
  },
  {
    exact: true,
    path: "/app/keyvalue",
    component: () => <Redirect to="/app/keyvalue/list" />
  },
  {
    exact: true,
    path: "/app/keyvalue/list",
    component: lazy(() => import("src/views/keyvalue/KeyvalueListView"))
  },
  {
    exact: true,
    path: "/app/keyvalue/create",
    component: lazy(() => import("src/views/keyvalue/KeyvalueUpsertView"))
  },
  {
    exact: true,
    path: "/app/keyvalue/:keyvalueId/edit",
    component: lazy(() => import("src/views/keyvalue/KeyvalueUpsertView"))
  },
  {
    exact: true,
    path: "/app/account",
    component: lazy(() => import("src/views/pages/AccountView"))
  },
  /*
  {
    exact: true,
    path: "/app/reports/dashboard",
    component: lazy(() => import("src/views/reports/DashboardView"))
  },
  {
    exact: true,
    path: "/app/reports/dashboard-alternative",
    component: lazy(() => import("src/views/reports/DashboardAlternativeView"))
  },
  {
    exact: true,
    path: "/app/reports",
    component: () => <Redirect to="/F" />
  },
  {
    exact: true,
    path: "/app/extra/charts/apex",
    component: lazy(() => import("src/views/extra/charts/ApexChartsView"))
  },
  {
    exact: true,
    path: "/app/extra/forms/formik",
    component: lazy(() => import("src/views/extra/forms/FormikView"))
  },
  {
    exact: true,
    path: "/app/extra/forms/redux",
    component: lazy(() => import("src/views/extra/forms/ReduxFormView"))
  },
  {
    exact: true,
    path: "/app/extra/editors/draft-js",
    component: lazy(() => import("src/views/extra/editors/DraftEditorView"))
  },
  {
    exact: true,
    path: "/app/extra/editors/quill",
    component: lazy(() => import("src/views/extra/editors/QuillEditorView"))
  },
  */
  {
    component: () => <Redirect to="/404" />
  }
];

const Routes = () => {
  const renderRoutes = routes =>
    routes ? (
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={props => (
                  <Layout>
                    <Guard>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Guard>
                  </Layout>
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    ) : null;

  const routesConfig = [
    {
      exact: true,
      path: "/",
      component: () => <Redirect to="/app" />
    },
    {
      exact: true,
      path: "/loginsuccess",
      guard: GoogleAuthGuard,
      component: () => <Redirect to="/app" />
    },
    {
      exact: true,
      path: "/404",
      component: lazy(() => import("src/views/pages/Error404View"))
    },

    {
      exact: true,
      path: "/login",
      component: lazy(() => import("src/views/auth/LoginView"))
    },
    {
      exact: true,
      path: "/login-unprotected",
      component: lazy(() => import("src/views/auth/LoginView"))
    },
    {
      exact: true,
      path: "/register",
      component: lazy(() => import("src/views/auth/RegisterView"))
    },
    {
      exact: true,
      path: "/register-unprotected",
      component: lazy(() => import("src/views/auth/RegisterView"))
    },
    {
      path: "/app",
      layout: DashboardLayout,
      guard: AuthGuard,
      routes: mainRoutes
    },

    {
      path: "*",
      component: () => <Redirect to="/404" />
    }
  ];

  return renderRoutes(routesConfig);
};

Routes.propTypes = {
  accessToken: PropTypes.string
};

export default Routes;
